<template>
  <section class="hero is-fullheight">
    <ModalTransactionStatus
      v-if="modalTransactionStatusActive"
      :data-detail="transactionData"
      :balance-user="balanceUser"
      :active="modalTransactionStatusActive"
      :loading="loadingModal"
      @close-modal="modalTransactionStatusActive = false"
      @update-status-action="updateStatusAction"
    />
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Detalle de transacción
                  </h1>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <button
                    class="button btn_aqua is-normal"
                    @click="modalTransactionStatusActive = true"
                    :disabled="transactionData.status === APPROVED"
                  >
                    Editar estatus
                  </button>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!loading">
            <div class="column is-12">
              <transaction-data
                :data-detail="transactionData"
              />
            </div>
            <div class="column is-6">
              <transaction-provider-information
                :data-detail="transactionData"
              />
            </div>
            <div class="column is-6">
              <transaction-interested-information
                :data-detail="transactionData"
                :balance-user="balanceUser"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'TransactionDetailMain',
  components: {
    TransactionData: () => import('@/components/Transactions/Detail/TransactionData.vue'),
    TransactionProviderInformation: () => import('@/components/Transactions/Detail/TransactionProviderInformation.vue'),
    TransactionInterestedInformation: () => import('@/components/Transactions/Detail/TransactionInterestedInformation.vue'),
    ModalTransactionStatus: () => import('@/components/Transactions/Detail/ModalTransactionStatus.vue')
  },
  data () {
    return {
      APPROVED: 2,
      loading: false,
      loadingModal: false,
      transactionId: this.$route.params.transactionId,
      modalTransactionStatusActive: false,
      transactionData: {},
      balanceUser: null
    }
  },
  methods: {
    ...mapActions(['getTransactionDetail', 'changeStatusTransaction']),
    async getDetail () {
      this.loading = true
      const { success, payload } = await this.getTransactionDetail(this.transactionId)
      if (success) {
        this.transactionData = payload.transaction
        this.balanceUser = payload.totalUserBalance?.balance
      }
      this.loading = false
    },
    async updateStatusAction (dataUpdate) {
      this.loadingModal = true
      const { success } = await this.changeStatusTransaction(dataUpdate)
      if (success) {
        this.modalTransactionStatusActive = false
      }
      this.getDetail()
      this.loadingModal = false
    }
  },
  beforeMount () {
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    height: 100%;
  }
</style>
